import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

let map = null;

// Connects to data-controller="info-map"
export default class extends Controller {
  static targets = ['map', 'place'];

  connect() {
    this.initMap();
  }

  async initMap() {
    const position = new google.maps.LatLng(parseFloat(this.element.dataset.latitude), parseFloat(this.element.dataset.longitude));

    const { Map } = await google.maps.importLibrary('maps');

    map = new Map(this.mapTarget, {
      zoom: 15,
      center: position,
      mapId: 'map'
    });

    this.addSingleMarker(position, map, this.element.dataset.guideName, 'Home');

    this.placeTargets.forEach((place) => {
      this.addSingleMarker(
        new google.maps.LatLng(parseFloat(place.dataset.latitude), parseFloat(place.dataset.longitude)),
        map,
        place.dataset.title,
        place.dataset.description
      );
    });
  }

  async addSingleMarker(position, map, title = null, description = null) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    const marker = new AdvancedMarkerElement({
      map: map,
      position: position
    });

    const infoWindow = new google.maps.InfoWindow({
      headerContent: title,
      content: `<p class="tracking-wide">${description}</p>`,
      ariaLabel: title
    });

    marker.addListener('click', () => {
      infoWindow.open({
        anchor: marker,
        map
      });
    });
  };

  openDialog() {
    enter(document.querySelector('[role="dialog"]'));
    enter(document.querySelector('[data-drawer-target="drawer"]'));
  }

  closeDialog() {
    leave(document.querySelector('[role="dialog"]'));
    leave(document.querySelector('[data-drawer-target="drawer"]'));
  }

  placeClick(e) {
    this.closeDialog();

    const position = new google.maps.LatLng(parseFloat(e.target.dataset.latitude), parseFloat(e.target.dataset.longitude));
    map.setCenter(position);
  }
}
